.disconnectText {
  text-align: center;
  margin-top: 16px;
  background: linear-gradient(-65deg, #0163e0 0%, #08b2d2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
}
